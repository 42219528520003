<template>
    <div>
        <b-row>
            <b-col md="12">
              <data-kegiatan></data-kegiatan>>
            </b-col>
        </b-row>

    </div>
</template>

<script>
    import {
        BFormInput,
        BRow,
        BCol,
        BFormGroup
    } from 'bootstrap-vue' ;
    import DataKegiatan  from './component/DataKegiatan.vue'

    export default {
        components: {
            BFormInput,
            BFormGroup,
            BRow,
            BCol,DataKegiatan 
        },
    }
</script>

<style>

</style>