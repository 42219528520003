<template>
    <div>
        <b-row>
            <b-col md="6"></b-col>
            <b-col md="6">
                <b-form-input id="smallInput" size="sm" placeholder="Small Input" />
            </b-col>
        </b-row>

    </div>
</template>

<script>
    import {
        BFormInput,
        BRow,
        BCol,
        BFormGroup
    } from 'bootstrap-vue'

    export default {
        components: {
            BFormInput,
            BFormGroup,
            BRow,
            BCol,
        },
    }
</script>

<style>

</style>